import React from "react";
import PropTypes from "prop-types";
import Image from "react-bootstrap/Image";
import Layout from "../../../../components/layout";
import OrderButton from "../../../../components/orderbutton";
import ProductTable from "../../../../components/productTable";
import SEO from "../../../../components/seo";
import { Col, Container, Row } from "react-bootstrap";
import Datasheet from "../../../../content/produk/controller/videoprocessor/RGBLINK VENUS X1/datasheet-rgblink-venus-x1.pdf";
import Content from "../../../../content/produk/controller/videoprocessor/RGBLINK VENUS X1/Product-Page.json";
import ProductImage from "../../../../images/product/controller/videoprocessor/RGBLINK VENUS X1/Product-Summary-RGBLink-Venus-X1.jpg";
const rgblinkx1 = () => (
    <Layout>
        <SEO
            title={Content.seo.title}
            description={Content.seo.description}
            lang={Content.seo.lang}
        />
        <section id={"produk"} className={"section-bg"}>
            <Container>
                <div className={"section-title"}>
                    <h1>Video Processor RGBlink Venus X1</h1>
                </div>
                <Row>
                    <Col>
                        <Image src={ProductImage} fluid alt={"Gambar modul"} />
                    </Col>
                </Row>
                <Row className={"mt-5"}>
                    <Col>
                        <div className={"section-title"}>
                            <h2>Product Summary</h2>
                        </div>
                        <p style={{ textAlign: "justify" }}>
                            X1 adalah video processor yang cocok untuk
                            penggunaan sehari-hari. X1 memiliki fitur sistem
                            sinyal modular unik RGBlink yang memungkinkan
                            pengguna untuk membaca bermacam-macam sinyal hingga
                            3 posisi slot. X1 menggabungkan fade in dan fade out
                            yang halus dengan teknologi scaling yang canggih
                            untuk aplikasi dari event maupun sinyal digital.
                            Fitur onboard termasuk PIP/PBP preset, transisi
                            video digital antar sumber dan manajemen EDID sudah
                            built in untuk kompatibilitas yang baik, selagi
                            resolusi output dapat dikustomisasi sesuai
                            kebutuhan. kendali X1 dari panel depan, dengan
                            tombol besar yang berpendar untuk layar OLED dan
                            sinyal, atau kendalikan dan konfigurasi melalui
                            aplikasi desktop atau aplikasi mobile.
                        </p>
                        <ul style={{ textAlign: "justify" }}>
                            <li>Seamless switching between any source</li>
                            <li>
                                Scaling with configurable Horz & Vert Offsets
                            </li>
                            <li>Image enhancement</li>
                            <li>Transition effects</li>
                            <li>Split function</li>
                            <li>
                                PIP from any source in any position and size
                            </li>
                            <li>On board EDID management</li>
                            <li>HDCP compliant</li>
                            <li>Easy, intuitive operation</li>
                            <li>
                                Modular construction with innovative RGBlink
                                plug-n-play architecture
                            </li>
                            <li>
                                Add a wide range of input options to suit
                                particular requirements
                            </li>
                            <li>
                                Optional WIFI Hot Spot module for use with
                                remote apps
                            </li>
                            <li>Optional audio management module</li>
                        </ul>
                        <p>
                            <OrderButton
                                messageText={Content.messageText}
                                datasheet={Datasheet}
                            />
                        </p>
                    </Col>
                    <Col>
                        <div className={"section-title"}>
                            <h2 className={"text-center"}>Specification</h2>
                        </div>
                        <ProductTable
                            tableName={"LED Unit Cabinet"}
                            tableData={Content.specification}
                        />
                    </Col>
                </Row>
            </Container>
        </section>
    </Layout>
);

rgblinkx1.propTypes = {
    siteTitle: PropTypes.string,
};

rgblinkx1.defaultProps = {
    siteTitle: ``,
};
export default rgblinkx1;
